/* eslint-disable */
import { useState } from "react";
import { saveLocalData } from "../utils/common-util";
import { KEYS } from "../constants-keys";
import { loginAdmin } from "../api/admin.api";
import { getApiClient } from "../api/client.api";

const LoginAdminPage = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleLogin = async (e: React.FormEvent) => {
    const apiClient = getApiClient();
    e.preventDefault();

    loginAdmin(apiClient, { username, password })
      .then((res) => {
        if (res.resultCode === 0) {
          saveLocalData(KEYS.IS_ADMIN, "true");
          saveLocalData(KEYS.LOGIN_USER, username);
          window.location.reload();
        } else {
          const result = res.resultMsg;
          setError(result);
        }
      })
      .catch((err) => {
        const result = err;
        setError(result);
      });
  };

  return (
    <div className="login-admin">
      <form onSubmit={handleLogin} className="login-form">
        <div className="title">ADMIN PAGE</div>
        <label>
          <input
            placeholder="username"
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </label>
        <br />
        <label>
          <input
            placeholder="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </label>
        <br />
        <button className="submit-btn" type="submit">
          Log in
        </button>

        <div className="error">{error}</div>
      </form>
    </div>
  );
};

export default LoginAdminPage;
