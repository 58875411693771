export enum SORT_TYPE {
  RECENT = "최신순",
  OLDEST = "오래된 순",
}
export enum UX_KEYWORD {
  CONFIRMATION_BIAS = "Confirmation Bias",
  PRIMING = "Priming",
  HICKS_LAW = "Hick’s Law",
  FRAIMING = "Framing",
  SPARK_EFFECT = "Spark Effect",
}
export enum UX_EVALUATION {
  GOOD = "Good UX",
  BAD = "Bad UX",
}

export enum AUTHOR_CONTACT_TYPE {
  EMAIL = "EMAIL",
  INSTAGRAM = "INSTAGRAM",
  LINKEDIN = "LINKEDIN",
  ARTICLE_LINK = "ARTICLE_LINK",
}
