import { KEYS } from "./constants-keys";

export const APP_ENV_TYPE = {
  DEV: "development",
  STAGE: "staging",
  PROD: "production",
};

const AppConfig = {
  ENV: APP_ENV_TYPE.DEV,
  /**
   * API SERVER
   */
  API_SERVER: "https://uxpirates.xyz/api",
  // API_SERVER: "http://localhost:3001/api",
  HOST: "0.0.0.0",
  // API_SERVER: "http://localhost:8080/api/v1",
  // API_SERVER: "https://vote-share.kro.kr/",
  /**
   * FILE SERVER
   */
  // FILE_SERVER: "https://cdn.grabthecrack.com/",

  /**
   * ETC
   */
  KEYS,
};
export default AppConfig;
