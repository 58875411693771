export default function NoArticles() {
  return (
    <div className="no-articles-container">
      <div className="no-articles-title">
        이 필터에 해당하는 UX 아티클이 없어요.
      </div>
      <div className="no-articles-sub">다른 UX 키워드 필터를 적용해보세요</div>
    </div>
  );
}
