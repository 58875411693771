import { useState } from "react";
import ArticleForm from "../components/admin/ArticleForm";
import { Article } from "../types/type";

export default function AdminPost() {
  const [articles, setArticles] = useState<Article[]>([]);
  const handleAddArticle = (newArticle: Article) => {
    fetch("/api/articles", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newArticle),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to add article");
        }
        return response.json();
      })
      .then((data) => setArticles([...articles, data]))
      .catch((error) => console.error("error 발생", error));
  };
  return (
    <div className="admin-post-page">
      <ArticleForm onAddArticle={handleAddArticle} />
    </div>
  );
}
