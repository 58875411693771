/* eslint-disable */
import { useCallback, useEffect, useState } from "react";
import ColumnPreview from "../components/home/ColumnPreview";
import KeywordFilter from "../components/home/KeywordFilter";
import {
  Article,
  columnDataInterface,
  KeywordsWithCategoryData,
  ProductsData,
  UxEvaluationCount,
} from "../types/type";
import {
  getArticleFromId,
  getArticles,
  getUxEvaluationCount,
} from "../api/articles.api";
import { getApiClient } from "../api/client.api";
import Detail from "../pages/Detail";
import { useSearchParams } from "react-router-dom";
import NoArticles from "../components/home/NoArticles";
import SortDropDown from "../components/home/SortDropDown";
import { SORT_TYPE, UX_EVALUATION } from "../types/enum";
import FilteredSearchComponent from "../components/home/FilteredSearchComponent";
import { getKeywordsWithCategory } from "../api/keywords.api";
import { getProducts } from "../api/products.api";
import { ShowFilterBtn } from "../components/mobile/ShowFilterBtn";

export default function Home() {
  const apiClient = getApiClient();
  const [searchParams] = useSearchParams();
  const [products, setProducts] = useState<ProductsData[]>();
  const [articles, setArticles] = useState<Article[] | undefined>([]); // const [isOpenedDetailModal, setIsOpened] = useState(false);
  const [isOpenedDetailModal, setIsOpenedDetailModal] = useState(false);
  const [selectedDetailId, setSelectedDetailId] = useState<
    string | undefined
  >();
  const [selectedArticle, setSelectedArticle] = useState<columnDataInterface>();
  const [filteredArticles, setFilteredArticles] = useState<
    Article[] | undefined
  >([]);
  const [isOpenedSortDropDown, setIsOpenedSortDropDown] = useState(false);
  const [selectedType, setSelectedType] = useState(SORT_TYPE.RECENT);
  const [keywordsData, setKeywordsData] =
    useState<KeywordsWithCategoryData[]>();
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [onResetTriggered, setOnResetTriggered] = useState(false);
  const [resultNum, setResultNum] = useState(0);
  const [uxEvaluations, setUxEvaluations] = useState<UxEvaluationCount[]>();

  const handleIsOpenedDropDown = (isOpened: boolean) => {
    setIsOpenedSortDropDown(isOpened);
  };
  const handleSelectedSortType = (selectedType: SORT_TYPE) => {
    setSelectedType(selectedType);

    // generateTime 기준으로 내림차순(최신) 정렬
    const sortedArticles = filteredArticles?.sort((a, b) => {
      if (selectedType === SORT_TYPE.RECENT) {
        return (
          new Date(b.generateTime!).getTime() -
          new Date(a.generateTime!).getTime()
        );
      } else if (selectedType === SORT_TYPE.OLDEST) {
        return (
          new Date(a.generateTime!).getTime() -
          new Date(b.generateTime!).getTime()
        );
      }
      return (
        new Date(b.generateTime!).getTime() -
        new Date(a.generateTime!).getTime()
      );
    });
    // 정렬된 결과를 상태에 반영
    setFilteredArticles(sortedArticles);
  };

  useEffect(() => {
    if (window.location.href.includes("/detail")) {
      setSelectedDetailId(window.location.href.split("/detail/")[1]);
    }
  }, []);

  useEffect(() => {
    if (selectedDetailId) {
      getArticleFromId(apiClient, selectedDetailId).then((res) => {
        setSelectedArticle(res.data);
      });
      setIsOpenedDetailModal(true);
    }
  }, [selectedDetailId]);

  useEffect(() => {
    getArticles(apiClient).then((res) => {
      setArticles(res.data);
      setResultNum(res.data?.length ? res.data.length : 0);
    });
    // if (selectedDetailId != null) {
    //   getArticleFromId(apiClient, selectedDetailId).then((res) => {
    //     setSelectedArticle(res.data);
    //   });
    // }
    getUxEvaluationCount(apiClient).then((res) => {
      setUxEvaluations([
        {
          tagId: "GOOD",
          tagName: UX_EVALUATION.GOOD,
          count: res.data?.goodUxCount,
        },
        {
          tagId: "BAD",
          tagName: UX_EVALUATION.BAD,
          count: res.data?.badUxCount,
        },
      ]);
    });
    getProducts(apiClient).then((res) => {
      setProducts(res.data);
    });
    getKeywordsWithCategory(apiClient).then((res) => {
      setKeywordsData(res.data);
    });
  }, []);

  const [isMobile, setIsMobile] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // 모바일 기준 너비 조정
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const openMobileMenu = () => setIsMobileMenuOpen(true);
  const closeMobileMenu = () => setIsMobileMenuOpen(false);

  useEffect(() => {
    if (isOpenedDetailModal || isMobileMenuOpen) {
      document.body.classList.add("no-scroll");
      document.documentElement.style.overflowY = "hidden";
      document.body.style.overflowY = "hidden";
    } else {
      document.body.classList.remove("no-scroll");
      document.documentElement.style.overflowY = "";
      document.body.style.overflowY = "";
    }
  }, [isOpenedDetailModal, isMobileMenuOpen]);

  useEffect(() => {
    const allKeywords = searchParams.getAll("keyword");
    const allProducts = searchParams.getAll("product");
    const allEvaluations = searchParams.getAll("uxEvaluation");

    const filteredArticles = articles?.filter((article) => {
      const matchesKeywords =
        allKeywords.length === 0 ||
        allKeywords.every((keyword) =>
          article.keywordId?.includes(keyword.split("_")[1]),
        );
      const matchesProducts =
        allProducts.length === 0 ||
        allProducts.every((product) =>
          article.productId?.includes(product.split("_")[1]),
        );
      const matchesUxEvaluations =
        allEvaluations.length === 0 ||
        allEvaluations.every((evaluations) => {
          const paramValue =
            evaluations.split("_")[1] === "GOOD"
              ? UX_EVALUATION.GOOD
              : UX_EVALUATION.BAD;
          return article.uxEvaluation?.includes(paramValue);
        });
      return matchesKeywords && matchesProducts && matchesUxEvaluations;
    });

    const sortedArticles = filteredArticles?.sort((a, b) => {
      if (selectedType === SORT_TYPE.RECENT) {
        return (
          new Date(b.generateTime!).getTime() -
          new Date(a.generateTime!).getTime()
        );
      } else if (selectedType === SORT_TYPE.OLDEST) {
        return (
          new Date(a.generateTime!).getTime() -
          new Date(b.generateTime!).getTime()
        );
      }
      return (
        new Date(b.generateTime!).getTime() -
        new Date(a.generateTime!).getTime()
      );
    });
    // 정렬된 결과를 상태에 반영
    setFilteredArticles(sortedArticles);

    setResultNum(filteredArticles ? filteredArticles.length : 0);
    if (
      allKeywords.length > 0 ||
      allProducts.length > 0 ||
      allEvaluations.length > 0
    ) {
      setShowSearchBar(true);
    } else {
      // 필터가 모두 해제된 경우 showSearchBar를 false로 설정
      setShowSearchBar(false);
    }
  }, [searchParams, articles]);

  const handleResetFilters = useCallback(() => {
    setOnResetTriggered(true);
    setShowSearchBar(false);
    setFilteredArticles(articles);
  }, []);

  return (
    <section className="home-page">
      {isOpenedDetailModal ? (
        <Detail
          key={selectedDetailId}
          id={selectedDetailId}
          data={selectedArticle}
          setIsOpened={setIsOpenedDetailModal}
        />
      ) : null}
      {isOpenedDetailModal ? (
        <div
          className="dim"
          onClick={() => {
            setIsOpenedDetailModal(false);
            window.history.pushState(null, "", window.location.origin);
          }}
        ></div>
      ) : null}

      <div className="bottom-view">
        <KeywordFilter
          products={products!}
          keywordsData={keywordsData!}
          uxEvaluationsCount={uxEvaluations!}
          onReset={onResetTriggered}
          setOnResetTriggered={setOnResetTriggered}
          isMobile={isMobile}
          isMobileMenuOpen={isMobileMenuOpen}
          closeMobileMenu={closeMobileMenu}
        />
        <div className="filter-right-view">
          <div className="filtered-summary">
            <SortDropDown
              handleIsOpenedDropDown={handleIsOpenedDropDown}
              handleSelectedSortType={handleSelectedSortType}
              isOpened={isOpenedSortDropDown}
              selectedType={selectedType}
            />
            <div className="result-num">총 {resultNum}개의 결과</div>
          </div>
          {isMobile && (
            <div onClick={openMobileMenu}>
              <ShowFilterBtn />
            </div>
          )}
          {/*<SearchComponent />*/}
          {showSearchBar ? (
            <FilteredSearchComponent
              products={products!}
              onReset={handleResetFilters}
              uxEvaluations={uxEvaluations!}
            />
          ) : null}
          <div className="colum-list">
            {filteredArticles?.length !== 0 ? (
              filteredArticles?.map((el) => {
                return (
                  <ColumnPreview
                    key={el.id}
                    id={el.id}
                    title={el.title}
                    content={el.content}
                    uxEvaluation={el.uxEvaluation}
                    imageSrc={el.images!}
                    product={el.product}
                    author={el.author}
                    authorEmail={el.authorEmail}
                    authorInsta={el.authorInsta}
                    authorLinkedIn={el.authorLinkedIn}
                    onClickDetail={setSelectedDetailId}
                    setIsOpened={setIsOpenedDetailModal}
                    productImage={el.productImage}
                  />
                );
              })
            ) : (
              <NoArticles />
            )}
          </div>
        </div>
      </div>
      {isMobile && isMobileMenuOpen ? (
        <div
          className="mobile-dim"
          onClick={() => setIsMobileMenuOpen(false)}
        ></div>
      ) : null}
    </section>
  );
}
