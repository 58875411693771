import { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";

export default function Header() {
  const [isInAdminPage, setIsInAdminPage] = useState(false);
  const location = useLocation();
  useEffect(() => {
    location.pathname.includes("admin")
      ? setIsInAdminPage(true)
      : setIsInAdminPage(false);
  }, [location]);

  return (
    <section className="header">
      <NavLink to={`${process.env.PUBLIC_URL}/`}>
        {/*<img*/}
        {/*  className="header-logo"*/}
        {/*  src={process.env.PUBLIC_URL + "/assets/images/header-logo.svg"}*/}
        {/*  alt="header-logo"*/}
        {/*/>*/}

        <div className="logo">
          {isInAdminPage ? "앱 로고 - Admin Page" : "🏴‍☠️ UX 해적단"}
        </div>
      </NavLink>
      {isInAdminPage ? null : (
        <a
          className="study-form-link-btn"
          href="https://forms.gle/qHAcn9VPgv4nbUUJ8"
          target="_blank"
          rel="noreferrer"
        >
          <div>UX 스터디 참여하기</div>
          <img
            src={process.env.PUBLIC_URL + "/assets/icons/ArrowRightWhite.svg"}
          />
        </a>
      )}
    </section>
  );
}
