// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import React, { useEffect, useState } from "react";
import {
  ExpandedCategories,
  KeywordsWithCategoryData,
  ProductsData,
  UxEvaluationCount,
} from "../../types/type";
import { useSearchParams } from "react-router-dom";

type KeywordFilterProps = {
  keywordsData: KeywordsWithCategoryData[];
  products: ProductsData[];
  uxEvaluationsCount: UxEvaluationCount[];
  onReset: boolean;
  setOnResetTriggered: (state: boolean) => void;
  isMobile: boolean;
  isMobileMenuOpen: boolean;
  closeMobileMenu: () => void;
};

export default function KeywordFilter({
  keywordsData,
  products,
  uxEvaluationsCount,
  onReset,
  setOnResetTriggered,
  isMobile,
  isMobileMenuOpen,
  closeMobileMenu,
}: KeywordFilterProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [expandedCategories, setExpandedCategories] =
    useState<ExpandedCategories>();
  const [isExpandedProduct, setIsExpandedProduct] = useState(false);

  const toggleExpandCategory = (categoryId: string): void => {
    setExpandedCategories((prevState) => {
      const safePrevState = prevState || {};
      return {
        ...safePrevState,
        [categoryId]: !safePrevState[categoryId],
      };
    });
  };

  const toggleExpandProduct = () => {
    setIsExpandedProduct((prevState) => !prevState);
  };

  useEffect(() => {
    if (onReset) {
      const params = new URLSearchParams();
      setSearchParams(params);
      setOnResetTriggered(false);
    }
  }, [onReset, setSearchParams, setOnResetTriggered]);

  const handleFilterChange = (
    filterType: "keyword" | "product" | "uxEvaluation",
    id: string,
    checked: boolean,
  ) => {
    const paramName = `${filterType}_${id}`;

    if (checked) {
      searchParams.append(filterType, paramName);
    } else {
      const paramsToRemove = searchParams.getAll(filterType);
      searchParams.delete(filterType);

      paramsToRemove.forEach((param) => {
        if (param !== paramName) {
          searchParams.append(filterType, param);
        }
      });
    }

    setSearchParams(searchParams);
  };

  const uxTipKeywords =
    keywordsData &&
    keywordsData.filter((keyword) => keyword.categoryName === "UXtip");
  const otherKeywords =
    keywordsData &&
    keywordsData.filter((keyword) => keyword.categoryName !== "UXtip");

  const handleDivClick = (e: React.MouseEvent, id: string) => {
    e.preventDefault();
    e.stopPropagation();

    const inputElement = document.getElementById(id) as HTMLInputElement;
    if (inputElement) {
      inputElement.click();
    }
  };

  return (
    <div
      className={`keyword-filter ${
        isMobile ? "mobile" : ""
      } ${isMobileMenuOpen ? "open" : ""}`}
    >
      <div className="menu-title">
        <div className="title-text">UX 키워드 필터링</div>
        <div className="done-btn" onClick={closeMobileMenu}>
          완료
        </div>
      </div>
      <div className="for-padding-top"></div>
      <div className="category-box">
        <div className="big-category">☠️ UX 분석</div>
        <div className="small-category-list">
          {uxEvaluationsCount &&
            uxEvaluationsCount.map((el) => {
              return (
                <div
                  key={el.tagId}
                  className="small-category"
                  onClick={(e) => handleDivClick(e, el.tagId)}
                >
                  <input
                    type="checkbox"
                    id={el.tagId}
                    checked={searchParams
                      .getAll("uxEvaluation")
                      .includes(`uxEvaluation_${el.tagId}`)}
                    onChange={(e) =>
                      handleFilterChange(
                        "uxEvaluation",
                        el.tagId!,
                        e.target.checked,
                      )
                    }
                  />
                  <label htmlFor={el.tagId}></label>
                  <div className="category-name">{el.tagName}</div>
                  <div className="number-of-post">{el.count}</div>
                </div>
              );
            })}
        </div>
        <div className="divide-line"></div>
      </div>

      {uxTipKeywords &&
        uxTipKeywords.map((el) => {
          const isExpanded = expandedCategories
            ? expandedCategories[el.categoryId!]
            : false;
          return (
            <div key={el.categoryId} className="category-box">
              <div className="big-category">☠️ {el.categoryKorName}</div>
              <div className="small-category-list">
                {el.keywords
                  ?.slice(0, isExpanded ? el.keywords.length : 7)
                  .map((sub) => {
                    return (
                      <div
                        key={sub.keywordKorName}
                        className="small-category"
                        onClick={(e) => handleDivClick(e, sub.keywordId)}
                      >
                        <input
                          type="checkbox"
                          id={sub.keywordId!}
                          checked={searchParams
                            .getAll("keyword")
                            .includes(`keyword_${sub.keywordId}`)}
                          onChange={(e) =>
                            handleFilterChange(
                              "keyword",
                              sub.keywordId!,
                              e.target.checked,
                            )
                          }
                        />
                        <label htmlFor={sub.keywordId}></label>
                        <div className="category-name">
                          {sub.keywordKorName}
                        </div>
                        <div className="number-of-post">
                          {sub.numOfArticles}
                        </div>
                      </div>
                    );
                  })}
              </div>
              {el.keywords!.length > 7 && (
                <button
                  className="more-content"
                  onClick={() => toggleExpandCategory(el.categoryId!)}
                >
                  {isExpanded ? "숨기기" : "더보기"}
                </button>
              )}
              <div className="divide-line"></div>
            </div>
          );
        })}

      <div className="category-box">
        <div className="big-category">☠️ 프로덕트</div>
        <div className="small-category-list">
          {products &&
            products
              .slice(0, isExpandedProduct ? products.length : 7)
              .map((el) => {
                return (
                  <div
                    key={el.id}
                    className="small-category"
                    onClick={(e) => handleDivClick(e, el.id)}
                  >
                    <input
                      type="checkbox"
                      id={el.id}
                      checked={searchParams
                        .getAll("product")
                        .includes(`product_${el.id}`)}
                      onChange={(e) =>
                        handleFilterChange("product", el.id!, e.target.checked)
                      }
                    />
                    <label htmlFor={el.id}></label>
                    <div className="category-name">{el.name}</div>
                    <div className="number-of-post">{el.numOfArticles}</div>
                  </div>
                );
              })}
        </div>
        <button className="more-content" onClick={toggleExpandProduct}>
          {isExpandedProduct ? "숨기기" : "더보기"}
        </button>
        <div className="divide-line"></div>
      </div>
      {otherKeywords &&
        otherKeywords.map((el) => {
          const isExpanded = expandedCategories
            ? expandedCategories[el.categoryId!]
            : false;
          return (
            <div key={el.categoryId} className="category-box">
              <div className="big-category">☠️ {el.categoryKorName}</div>
              <div className="small-category-list">
                {el.keywords
                  ?.slice(0, isExpanded ? el.keywords.length : 7)
                  .map((sub) => {
                    return (
                      <div
                        key={sub.keywordKorName}
                        className="small-category"
                        onClick={(e) => handleDivClick(e, sub.keywordId)}
                      >
                        <input
                          type="checkbox"
                          id={sub.keywordId!}
                          checked={searchParams
                            .getAll("keyword")
                            .includes(`keyword_${sub.keywordId}`)}
                          onChange={(e) =>
                            handleFilterChange(
                              "keyword",
                              sub.keywordId!,
                              e.target.checked,
                            )
                          }
                        />
                        <label htmlFor={sub.keywordId}></label>
                        <div className="category-name">
                          {sub.keywordKorName}
                        </div>
                        <div className="number-of-post">
                          {sub.numOfArticles}
                        </div>
                      </div>
                    );
                  })}
              </div>
              {el.keywords!.length > 7 && (
                <button
                  className="more-content"
                  onClick={() => toggleExpandCategory(el.categoryId!)}
                >
                  {isExpanded ? "숨기기" : "더보기"}
                </button>
              )}
              <div className="divide-line"></div>
            </div>
          );
        })}
    </div>
  );
}
