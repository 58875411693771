// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import React from "react";
import { AUTHOR_CONTACT_TYPE } from "../../types/enum";

export interface AuthorContactBtnProps {
  btnType: AUTHOR_CONTACT_TYPE;
  link: string;
}

export default function AuthorContactBtn(props: AuthorContactBtnProps) {
  const iconSrc = getAuthorContactIconSrc(props.btnType);
  const text = getAuthorContactText(props.btnType);

  const onClickBtn = async (e: React.MouseEvent) => {
    e.stopPropagation();
    if (props.btnType === AUTHOR_CONTACT_TYPE.EMAIL) {
      try {
        await navigator.clipboard.writeText(props.link);
        alert("클립보드에 링크가 복사되었습니다.");
      } catch (err) {
        alert("복사를 실패하였습니다. 다시 시도해주세요.");
        console.log(err);
      }
    } else {
      window.open(props.link, "_blank");
    }
  };
  return (
    <div className="author-contact-wrapper" onClick={(e) => onClickBtn(e)}>
      <img src={`${process.env.PUBLIC_URL}` + `/assets/icons/${iconSrc}.svg`} />
      <div className="link-href">{text}</div>
      {props.btnType === AUTHOR_CONTACT_TYPE.INSTAGRAM ||
      props.btnType === AUTHOR_CONTACT_TYPE.LINKEDIN ? (
        <img
          src={`${process.env.PUBLIC_URL}` + "/assets/icons/ArrowUpRight.svg"}
        />
      ) : null}
    </div>
  );
}

export function getAuthorContactIconSrc(props: AUTHOR_CONTACT_TYPE) {
  switch (props) {
    case AUTHOR_CONTACT_TYPE.EMAIL:
      return "FiEmail";
    case AUTHOR_CONTACT_TYPE.INSTAGRAM:
      return "InstagramLogo";
    case AUTHOR_CONTACT_TYPE.LINKEDIN:
      return "LinkedinLogo";
    case AUTHOR_CONTACT_TYPE.ARTICLE_LINK:
      return "FiLink";
  }
}

function getAuthorContactText(props: AUTHOR_CONTACT_TYPE) {
  switch (props) {
    case AUTHOR_CONTACT_TYPE.EMAIL:
      return "이메일 주소 복사";
    case AUTHOR_CONTACT_TYPE.INSTAGRAM:
      return "인스타그램";
    case AUTHOR_CONTACT_TYPE.LINKEDIN:
      return "링크드인";
    case AUTHOR_CONTACT_TYPE.ARTICLE_LINK:
      return "링크 복사";
  }
}
