import "./assets/css/app.scss";
import Router from "./router/index";

function App() {
  return (
    <>
      <Router></Router>
    </>
  );
}

export default App;
