import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "../pages/Home";
import ScrollToTop from "../components/common/ScrollToTop";
import Layout from "../components/common/Layout";
import AdminPage from "../pages/Admin";
import { loadLocalData } from "../utils/common-util";
import { KEYS } from "../constants-keys";
import LoginAdminPage from "../pages/LoginAdmin";
import AdminPost from "../pages/AdminPost";

export default function Router() {
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <ScrollToTop />
      {/*<div className="dim"></div>*/}
      <Layout />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/detail/:id" element={<Home />} />
        {loadLocalData(KEYS.IS_ADMIN) === "true" ? (
          <Route path="/admin" element={<AdminPage />} />
        ) : (
          <Route path="/admin" element={<LoginAdminPage />} />
        )}
        <Route path="/admin/post" element={<AdminPost />} />
      </Routes>
    </BrowserRouter>
  );
}
