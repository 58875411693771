interface FilterTagProps {
  name: string;
  onRemove: () => void;
}

export default function FilterTag({ name, onRemove }: FilterTagProps) {
  return (
    <div className="filter-tag-component" onClick={onRemove}>
      <img
        alt="funnel-icon"
        src={`${process.env.PUBLIC_URL}` + "/assets/icons/Funnel.svg"}
      />
      <div className="filter-tag-title">{name}</div>
      <img
        alt="close"
        className="delete-this-tag"
        src={`${process.env.PUBLIC_URL}` + "/assets/icons/X.svg"}
      />
    </div>
  );
}
