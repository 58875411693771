/* eslint-disable */
import { useState, useEffect } from "react";
import ArticleList from "../components/admin/ArticleList";
import { Article, Category, KeywordData, Product } from "../types/type";
import axios from "axios";
import { Link } from "react-router-dom";

const AdminPage = () => {
  const [articles, setArticles] = useState<Article[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [keywords, setKeywords] = useState<KeywordData[]>([]);
  const [products, setProducts] = useState<Product[]>([]);
  const [newCategory, setNewCategory] = useState<Category>({
    id: undefined,
    name: "",
    koreaName: "",
    var: "",
    numOfArticles: 0,
  });
  const [newProduct, setNewProduct] = useState<Product>({
    id: undefined,
    name: "",
    imageUrl: "",
    numOfArticles: 0,
  });

  const [newKeyword, setNewKeyword] = useState<KeywordData>({
    id: undefined,
    categoryId: undefined,
    categoryKorName: undefined,
    categoryName: undefined,
    name: "",
    numOfArticles: 0,
    koreaName: "",
    desc: "",
    studyLink: [],
  });

  useEffect(() => {
    fetch("/api/articles")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch articles");
        }
        return response.json();
      })
      .then((data) => {
        // 데이터가 배열인지 확인
        if (Array.isArray(data)) {
          setArticles(data);
        } else {
          throw new Error("Invalid data format");
        }
      })
      .catch((error) => console.error("Error:", error));

    const fetchCategoriesAndKeywordsAndProducts = async () => {
      try {
        const [catResponse, keywordResponse, productsResponse] =
          await Promise.all([
            axios.get("/api/categories"),
            axios.get("/api/keywords"),
            axios.get("/api/products"),
          ]);
        setCategories(catResponse.data);
        setKeywords(keywordResponse.data);
        setProducts(productsResponse.data);
      } catch (error) {
        console.error(
          "Failed to fetch categories and keywords and products:",
          error,
        );
      }
    };

    fetchCategoriesAndKeywordsAndProducts();
  }, []);

  const handleDeleteArticle = (id: string) => {
    fetch(`/api/articles/${id}`, { method: "DELETE" })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to delete article");
        }
        setArticles(articles.filter((article) => article.id !== id));
      })
      .catch((error) => console.error("Error:", error));
  };

  const handleCategoryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewCategory((prev) => ({ ...prev, [name]: value }));
  };
  const handleProductChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewProduct((prev) => ({ ...prev, [name]: value }));
  };
  const handleKeywordChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setNewKeyword((prev) => ({ ...prev, [name]: value }));
  };

  const handleAddCategory = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      newCategory.var = newCategory.name
        ?.toUpperCase()
        // @ts-ignore
        .replaceAll(" ", "")
        .replaceAll("'", "");

      const response = await axios.post("/api/categories", newCategory);
      setCategories((prev) => [...prev, response.data]);
      setNewCategory({
        id: undefined,
        name: "",
        koreaName: "",
        var: "",
        numOfArticles: 0,
      });
    } catch (error) {
      console.error("Failed to add category:", error);
    }
  };

  const handleAddProduct = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const response = await axios.post("/api/products", newProduct);
      setProducts((prev) => [...prev, response.data]);
      setNewProduct({
        id: undefined,
        name: "",
        imageUrl: "",
        numOfArticles: 0,
      });
    } catch (error) {
      console.error("Failed to add category:", error);
    }
  };

  const [studyLinks, setStudyLinks] = useState<string[]>([""]);

  const handleAddKeyword = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      console.log({
        ...newKeyword,
        studyLink: studyLinks,
      });
      const response = await axios.post("/api/keywords", {
        ...newKeyword,
        studyLink: studyLinks,
      });
      setKeywords((prev) => [...prev, response.data]);
      setNewKeyword({
        id: undefined,
        categoryId: undefined,
        categoryKorName: undefined,
        categoryName: undefined,
        name: "",
        numOfArticles: 0,
        koreaName: "",
        desc: "",
        studyLink: [],
      });
      setStudyLinks([]);
    } catch (error) {
      console.error("Failed to add keyword:", error);
    }
  };

  const handleStudyLinkChange = (index: number, value: string) => {
    const updatedLinks = [...studyLinks];
    updatedLinks[index] = value;
    setStudyLinks(updatedLinks);
  };

  const handleAddStudyLink = () => {
    setStudyLinks((prev) => [...prev, ""]); // 새로운 텍스트 영역 추가
  };

  const handleDeleteCategory = async (id: string | undefined) => {
    try {
      await axios.delete(`/api/categories/${id}`);

      setCategories((prev) => prev.filter((cat) => cat.id !== id));
    } catch (error) {
      console.error("Failed to delete category:", error);
    }
  };
  const handleDeleteProduct = async (id: string | undefined) => {
    try {
      await axios.delete(`/api/products/${id}`);

      setProducts((prev) => prev.filter((cat) => cat.id !== id));
    } catch (error) {
      console.error("Failed to delete category:", error);
    }
  };
  const handleDeleteKeyword = async (id: string | undefined) => {
    try {
      await axios.delete(`/api/keywords/${id}`);
      setKeywords((prev) => prev.filter((keyword) => keyword.id !== id));
    } catch (error) {
      console.error("Failed to delete keyword:", error);
    }
  };

  return (
    <div className="admin-page">
      <div className="article-title-wrapper">
        <div className="title">현재 게시글 리스트</div>
        <Link className="add-column-btn" to={"/admin/post"}>
          게시물 추가하러가기
        </Link>
      </div>
      <ArticleList articles={articles} onDeleteArticle={handleDeleteArticle} />
      {/* Add Category Form */}
      <h2>Add Category</h2>
      <form onSubmit={handleAddCategory}>
        <label>
          Name:
          <input
            type="text"
            name="name"
            value={newCategory.name}
            onChange={handleCategoryChange}
            required
          />
        </label>
        <label>
          Korean Name:
          <input
            type="text"
            name="koreaName"
            value={newCategory.koreaName}
            onChange={handleCategoryChange}
          />
        </label>
        <button type="submit">Add Category</button>
      </form>

      <h2>Add Product</h2>
      <form onSubmit={handleAddProduct}>
        <label>
          product 이름
          <input
            type="text"
            name="name"
            value={newProduct.name}
            onChange={handleProductChange}
            required
          />
        </label>
        <label>
          product 이미지
          <input
            type="url"
            name="imageUrl"
            value={newProduct.imageUrl}
            onChange={handleProductChange}
            placeholder="이미지 URL"
            required
          />
        </label>
        <button type="submit">Add Product</button>
      </form>

      {/* Add Keyword Form */}
      <h2>Add Keyword</h2>
      <form onSubmit={handleAddKeyword}>
        <label>
          Category:
          <select
            name="category"
            value={newKeyword.categoryName || ""}
            onChange={(e) => {
              const selectedCategory = categories.find(
                (cat) => cat.name === e.target.value,
              );
              setNewKeyword((prev) => ({
                ...prev,
                categoryId: selectedCategory?.id,
                categoryName: selectedCategory?.name,
                categoryKorName: selectedCategory?.koreaName,
              }));
            }}
          >
            <option value="">Select Category</option>
            {categories.map((cat) => (
              <option key={cat.id} value={cat.name}>
                {cat.name}
              </option>
            ))}
          </select>
        </label>
        <label>
          Name:
          <textarea
            name="name"
            value={newKeyword.name}
            onChange={(e) => handleKeywordChange(e)}
            required
          />
        </label>
        <label>
          Korean Name:
          <textarea
            name="koreaName"
            value={newKeyword.koreaName}
            onChange={(e) => handleKeywordChange(e)}
          />
        </label>
        <label>
          Description:
          <textarea
            name="desc"
            value={newKeyword.desc}
            onChange={(e) => handleKeywordChange(e)}
          />
        </label>
        <label>
          Study Links:
          {studyLinks.map((link, index) => (
            <textarea
              key={index}
              value={link}
              onChange={(e) => handleStudyLinkChange(index, e.target.value)}
            />
          ))}
        </label>
        <button type="button" onClick={handleAddStudyLink}>
          +
        </button>
        <button type="submit">Add Keyword</button>
      </form>

      {/* Category List */}
      <h2>Categories</h2>
      <ul>
        {categories.map((cat) => (
          <li key={cat.id}>
            {cat.name}{" "}
            <button onClick={() => handleDeleteCategory(cat.id)}>Delete</button>
          </li>
        ))}
      </ul>

      <h2>Products</h2>
      <ul>
        {products.map((pro) => (
          <li key={pro.id}>
            {pro.name}{" "}
            <button onClick={() => handleDeleteProduct(pro.id)}>Delete</button>
          </li>
        ))}
      </ul>

      {/* Keyword List */}
      <h2>Keywords</h2>
      <ul>
        {keywords.map((keyword) => (
          <li key={keyword.id}>
            {keyword.name} (Category: {keyword.categoryKorName}){" "}
            <button onClick={() => handleDeleteKeyword(keyword.id)}>
              Delete
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AdminPage;
