import { useEffect, useState } from "react";
import FilterTag from "../../components/tag/FilterTag";
import { KeywordData, ProductsData, UxEvaluationCount } from "../../types/type";
import { useSearchParams } from "react-router-dom";
import { getKeywords } from "../../api/keywords.api";
import { getApiClient } from "../../api/client.api";

type KeywordFilterProps = {
  products: ProductsData[];
  uxEvaluations: UxEvaluationCount[];
  onReset: () => void;
};

export default function FilteredSearchComponent({
  products = [],
  uxEvaluations,
  onReset,
}: KeywordFilterProps) {
  const apiClient = getApiClient();
  const [searchParams, setSearchParams] = useSearchParams();
  const [filteredTags, setFilteredTags] = useState<
    { type: string; id: string; name: string }[]
  >([]);
  const [keywords, setKeywords] = useState<KeywordData[] | undefined>();

  useEffect(() => {
    getKeywords(apiClient).then((res) => {
      setKeywords(res.data);
    });
  }, []);

  useEffect(() => {
    const allProductIds = searchParams.getAll("product");
    const allKeywordIds = searchParams.getAll("keyword");
    const allEvaluations = searchParams.getAll("uxEvaluation");

    const selectedProducts = allProductIds
      .map((id) => {
        const product = products?.find((p) => `product_${p.id}` === id);
        return product
          ? { type: "product", id: product.id, name: product.name }
          : null;
      })
      .filter(Boolean);

    const selectedKeywords = allKeywordIds
      .map((id) => {
        const keyword = keywords?.find((k) => `keyword_${k.id}` === id);
        return keyword
          ? {
              type: "keyword",
              id: keyword.id,
              name: keyword.koreaName,
            }
          : null;
      })
      .filter(Boolean);

    const selectedEvaluations = allEvaluations
      .map((id) => {
        const evaluation = uxEvaluations?.find(
          (p) => `uxEvaluation_${p.tagId}` === id,
        );
        return evaluation
          ? {
              type: "uxEvaluation",
              id: evaluation.tagId,
              name: evaluation.tagName,
            }
          : null;
      })
      .filter(Boolean);

    setFilteredTags([
      ...(selectedProducts.filter(
        (product) => product?.id && product?.name,
      ) as { type: string; id: string; name: string }[]),
      ...(selectedKeywords.filter(
        (keyword) => keyword?.id && keyword?.name,
      ) as { type: string; id: string; name: string }[]),
      ...(selectedEvaluations.filter(
        (evaluation) => evaluation?.id && evaluation?.name,
      ) as { type: string; id: string; name: string }[]),
    ]);
  }, [searchParams, products, keywords, uxEvaluations]);

  const handleRemoveFilter = (type: string, id: string) => {
    const paramName = `${type}_${id}`;
    const updatedParams = searchParams
      .getAll(type)
      .filter((param) => param !== paramName);

    searchParams.delete(type);
    updatedParams.forEach((param) => searchParams.append(type, param));

    setSearchParams(searchParams);
  };

  const resetFilters = () => {
    setFilteredTags([]); // 상태 초기화
    window.history.replaceState({}, "", `${window.location.pathname}`);
    onReset();
  };

  return (
    <div className="filtered-search-wrapper">
      <div className="reset-container" onClick={resetFilters}>
        <img
          alt="reset"
          src={`${process.env.PUBLIC_URL}` + "/assets/icons/ClockClockwise.svg"}
        />
        <div>초기화</div>
      </div>
      <div className="filtered-container">
        {filteredTags.map((tag) => (
          <FilterTag
            key={`${tag.type}_${tag.id}`}
            name={tag.name}
            onRemove={() => handleRemoveFilter(tag.type, tag.id)}
          />
        ))}
      </div>
    </div>
  );
}
