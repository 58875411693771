import { AUTHOR_CONTACT_TYPE } from "../../types/enum";
import {
  AuthorContactBtnProps,
  getAuthorContactIconSrc,
} from "../tag/AuthorContactBtn";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import React, { useState } from "react";

interface showAuthorContactBtnInterface {
  keyId: string;
  btnType: AuthorContactBtnProps[];
}

export const ShowAuthorContactBtn = (props: showAuthorContactBtnInterface) => {
  const [isOpenedAuthorDropDown, setIsOpenedAuthorDropDown] = useState(false);
  const url = window.location.href;

  const handleIsOpenedDropDown = (e: React.MouseEvent) => {
    e.stopPropagation();

    setIsOpenedAuthorDropDown((prev) => !prev);
  };

  const onClickLink = async (
    e: React.MouseEvent,
    btnType: AUTHOR_CONTACT_TYPE,
    link: string,
  ) => {
    e.stopPropagation();
    if (btnType === AUTHOR_CONTACT_TYPE.EMAIL) {
      try {
        await navigator.clipboard.writeText(link);
        alert("클립보드에 링크가 복사되었습니다.");
      } catch (err) {
        alert("복사를 실패하였습니다. 다시 시도해주세요.");
        console.log(err);
      }
    } else if (btnType === AUTHOR_CONTACT_TYPE.ARTICLE_LINK) {
      try {
        await navigator.clipboard.writeText(url + "detail/" + link);
        alert("클립보드에 링크가 복사되었습니다.");
      } catch (err) {
        alert("복사를 실패하였습니다. 다시 시도해주세요.");
        console.log(err);
      }
    } else {
      window.open(link, "_blank");
    }
  };

  return (
    <div className="show-author-contact-btn" key={props.keyId}>
      <button
        className="drop-down-btn"
        onClick={(e) => handleIsOpenedDropDown(e)}
      >
        <img
          src={`${process.env.PUBLIC_URL}` + "/assets/icons/DotsThree.svg"}
          alt="drop-down"
        />
      </button>
      {isOpenedAuthorDropDown ? (
        <div className="drop-down-list">
          {props.btnType.map((prop: AuthorContactBtnProps) => {
            return (
              <div
                key={`${props.keyId} ${prop.btnType}`}
                className="drop-down-element"
                onClick={(e) => onClickLink(e, prop.btnType, prop.link)}
              >
                <img
                  src={
                    `${process.env.PUBLIC_URL}` +
                    `/assets/icons/${getAuthorContactIconSrc(prop.btnType)}.svg`
                  }
                />
                <div className="drop-down-text">
                  {getAuthorContactText(prop.btnType)}
                </div>
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
};

function getAuthorContactText(props: AUTHOR_CONTACT_TYPE) {
  switch (props) {
    case AUTHOR_CONTACT_TYPE.EMAIL:
      return "이메일 주소 복사";
    case AUTHOR_CONTACT_TYPE.INSTAGRAM:
      return "인스타그램";
    case AUTHOR_CONTACT_TYPE.LINKEDIN:
      return "링크드인";
    case AUTHOR_CONTACT_TYPE.ARTICLE_LINK:
      return "링크 복사";
  }
}
