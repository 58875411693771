/* eslint-disable */
import { Axios } from "axios";
import { ResponseDto } from "../api/dto/response.dto";
import { AdminLoginEntity } from "../api/dto/admin.dto";

export const loginAdmin = (
  apiClient: Axios,
  data: AdminLoginEntity,
): Promise<ResponseDto<AdminLoginEntity>> => {
  const promiseFn = (
    fnResolve: (value: ResponseDto<AdminLoginEntity>) => void,
    fnReject: (reason?: any) => void,
  ) => {
    apiClient
      .post("/login", data)
      .then((res) => {
        if (!res) {
          console.error(res);
        } else {
          fnResolve(new ResponseDto<AdminLoginEntity>(res));
        }
      })
      .catch((err) => {
        console.error(err);
        fnReject(err.response.data.message);
      });
  };
  return new Promise(promiseFn);
};
