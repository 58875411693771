import { tagInterface } from "../../types/type";
import { UX_EVALUATION } from "../../types/enum";

export default function UxEvaluationTag(props: tagInterface) {
  return (
    <div
      className={`ux-evaluation-tag ${props.name === UX_EVALUATION.GOOD ? "good" : "bad"}`}
    >
      {props.name === UX_EVALUATION.GOOD ? (
        <img
          src={`${process.env.PUBLIC_URL}` + "/assets/icons/ThumbsUp.svg"}
          alt="good-ux-icon"
        />
      ) : (
        <img
          src={`${process.env.PUBLIC_URL}` + "/assets/icons/ThumbsDown.svg"}
          alt="bad-ux-icon"
        />
      )}

      <div className="name">{props.name}</div>
    </div>
  );
}
