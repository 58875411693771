/* eslint-disable */
import { Axios } from "axios";
import { ResponseDto } from "../api/dto/response.dto";
import { KeywordsDto, KeywordsWithCategoryDto } from "../api/dto/keywords.dto";

export const getKeywords = (
  apiClient: Axios,
): Promise<ResponseDto<KeywordsDto[]>> => {
  const promiseFn = (
    fnResolve: (value: ResponseDto<KeywordsDto[]>) => void,
    fnReject: (reason?: any) => void,
  ) => {
    apiClient
      .get("/keywords")
      .then((res) => {
        if (!res) {
          console.error(res);
        } else {
          fnResolve(new ResponseDto<KeywordsDto[]>(res));
        }
      })
      .catch((err) => {
        console.error(err);
        fnReject("msg.RESULT_FAILED");
      });
  };
  return new Promise(promiseFn);
};

export const getKeywordsWithCategory = (
  apiClient: Axios,
): Promise<ResponseDto<KeywordsWithCategoryDto[]>> => {
  const promiseFn = (
    fnResolve: (value: ResponseDto<KeywordsWithCategoryDto[]>) => void,
    fnReject: (reason?: any) => void,
  ) => {
    apiClient
      .get("/keywords/perCategory")
      .then((res) => {
        if (!res) {
          console.error(res);
        } else {
          fnResolve(new ResponseDto<KeywordsWithCategoryDto[]>(res));
        }
      })
      .catch((err) => {
        console.error(err);
        fnReject("msg.RESULT_FAILED");
      });
  };
  return new Promise(promiseFn);
};
