import { sortDropdownInterface } from "../../types/type";
import { SORT_TYPE } from "../../types/enum";

export default function SortDropDown(props: sortDropdownInterface) {
  // TODO open 되었을 때 dropdown width 안맞음
  return (
    <section className="sort-drop-down">
      <button
        className={
          props.isOpened
            ? "drop-down-btn drop-down-btn-active"
            : "drop-down-btn"
        }
        onClick={() => props.handleIsOpenedDropDown(!props.isOpened)}
      >
        <div>{props.selectedType}</div>
        <img
          src={`${process.env.PUBLIC_URL}` + "/assets/icons/CkChevronDown.svg"}
          alt="drop-down"
        />
      </button>
      {props.isOpened ? (
        <div
          className={
            props.isOpened
              ? "drop-down-list slide-fade-in-dropdown"
              : "drop-down-list slide-fade-out-dropdown"
          }
        >
          {Object.entries(SORT_TYPE).map((key) => {
            return (
              <div
                key={key[1]}
                onClick={() => {
                  props.handleSelectedSortType(key[1]);
                  props.handleIsOpenedDropDown(false);
                }}
                className={
                  key[1] === props.selectedType
                    ? "drop-down-elements selected-element"
                    : "drop-down-elements"
                }
              >
                {key[1]}
              </div>
            );
          })}
        </div>
      ) : null}
    </section>
  );
}
