/* eslint-disable */
import { Axios } from "axios";
import { ResponseDto } from "../api/dto/response.dto";
import { ProductsDto } from "../api/dto/products.dto";

export const getProducts = (
  apiClient: Axios,
): Promise<ResponseDto<ProductsDto[]>> => {
  const promiseFn = (
    fnResolve: (value: ResponseDto<ProductsDto[]>) => void,
    fnReject: (reason?: any) => void,
  ) => {
    apiClient
      .get("/products")
      .then((res) => {
        if (!res) {
          console.error(res);
        } else {
          fnResolve(new ResponseDto<ProductsDto[]>(res));
        }
      })
      .catch((err) => {
        console.error(err);
        fnReject("msg.RESULT_FAILED");
      });
  };
  return new Promise(promiseFn);
};
