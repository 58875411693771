import Header from "../../components/common/Header";

export default function Layout() {
  return (
    <section>
      <Header />
      <div className="bottom-line"></div>
    </section>
  );
}
