export const ShowFilterBtn = () => {
  return (
    <div className="show-filter-btn-for-mobile">
      <img
        alt="funnel-icon"
        src={`${process.env.PUBLIC_URL}` + "/assets/icons/Funnel.svg"}
      />
      <div className="filter-tag-title">UX 키워드 필터 보기</div>
    </div>
  );
};
