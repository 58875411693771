/* eslint-disable */
import { Axios } from "axios";
import { ResponseDto } from "../api/dto/response.dto";
import { ArticleEntity, UxEvaluationCount } from "../api/dto/articles.dto";

export const getArticles = (
  apiClient: Axios,
): Promise<ResponseDto<ArticleEntity[]>> => {
  const promiseFn = (
    fnResolve: (value: ResponseDto<ArticleEntity[]>) => void,
    fnReject: (reason?: any) => void,
  ) => {
    apiClient
      .get("/articles")
      .then((res) => {
        if (!res) {
          console.error(res);
        } else {
          fnResolve(new ResponseDto<ArticleEntity[]>(res));
        }
      })
      .catch((err) => {
        console.error(err);
        fnReject("msg.RESULT_FAILED");
      });
  };
  return new Promise(promiseFn);
};

export const getArticleFromId = (
  apiClient: Axios,
  id: string,
): Promise<ResponseDto<ArticleEntity>> => {
  const promiseFn = (
    fnResolve: (value: ResponseDto<ArticleEntity>) => void,
    fnReject: (reason?: any) => void,
  ) => {
    apiClient
      .get(`/articles/${id}`)
      .then((res) => {
        if (!res) {
          console.error(res);
        } else {
          fnResolve(new ResponseDto<ArticleEntity>(res));
        }
      })
      .catch((err) => {
        console.error(err);
        fnReject("msg.RESULT_FAILED");
      });
  };
  return new Promise(promiseFn);
};

export const getUxEvaluationCount = (
  apiClient: Axios,
): Promise<ResponseDto<UxEvaluationCount>> => {
  const promiseFn = (
    fnResolve: (value: ResponseDto<UxEvaluationCount>) => void,
    fnReject: (reason?: any) => void,
  ) => {
    apiClient
      .get("/articles/uxEvaluationCount")
      .then((res) => {
        if (!res) {
          console.error(res);
        } else {
          fnResolve(new ResponseDto<UxEvaluationCount>(res));
        }
      })
      .catch((err) => {
        console.error(err);
        fnReject("msg.RESULT_FAILED");
      });
  };
  return new Promise(promiseFn);
};
