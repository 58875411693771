export default function ShareBtn(props: { copyUrl: string }) {
  return (
    <a
      className="share-btn-box"
      href={props.copyUrl}
      target="_blank"
      rel="noreferrer"
    >
      <div className="share-text">공유하기</div>
      <img
        src={`${process.env.PUBLIC_URL}` + "/assets/icons/ArrowUpRight.svg"}
      />
    </a>
  );
}
