import AppConfig from "../constants";
import { loadLocalData } from "../utils/common-util";
import axios from "axios";

let apiClient = axios.create({
  baseURL: AppConfig.API_SERVER,
  headers: {
    "Content-type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Credentials": true,
  },
});

let apiFileClient = axios.create({
  baseURL: AppConfig.API_SERVER,
  headers: {
    "Content-type": "multipart/form-data",
    "Access-Control-Allow-Origins": "*",
    "Access-Control-Allow-Credentials": true,
  },
});
export const setApiBaseUrl = (url: string) => {
  if (apiClient) apiClient.defaults.baseURL = url;
};

// export const setAuthorizationToken = (token: string) => {
//   if (token) {
//     axios.defaults.headers.common["Authorization"] = `Vote ${token}`;
//   } else {
//     delete axios.defaults.headers.common["Authorization"];
//   }
// };

export function setApiToken(token: string): void {
  if (apiClient)
    apiClient.defaults.headers.common["Authorization"] = "Bearer " + token;
  if (apiFileClient)
    apiFileClient.defaults.headers.common["Authorization"] = "Bearer " + token;
}

export const getApiClient = () => {
  // const token = loadLocalData(AppConfig.KEYS.LOGIN_TOKEN);
  // if (token) setApiToken(token);
  if (!apiClient) {
    apiClient = axios.create({
      baseURL: AppConfig.API_SERVER,
      headers: {
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    });
  }
  return apiClient;
};

export const getFileApiClient = () => {
  const token = loadLocalData(AppConfig.KEYS.LOGIN_TOKEN);
  if (token) setApiToken(token);
  if (!apiFileClient) {
    apiFileClient = axios.create({
      baseURL: AppConfig.API_SERVER,
      headers: {
        "Content-type": "multipart/form-data",
        "Access-Control-Allow-Origins": "*",
      },
    });
  }
  return apiFileClient;
};
