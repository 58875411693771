import { columnPreviewInterface } from "../../types/type";
import UxEvaluationTag from "../../components/tag/UxEvaluationTag";
import AuthorContactBtn, {
  AuthorContactBtnProps,
} from "../tag/AuthorContactBtn";
import { AUTHOR_CONTACT_TYPE } from "../../types/enum";
import { ShowAuthorContactBtn } from "../mobile/ShowAuthorContactBtn";

export default function ColumnPreview(props: columnPreviewInterface) {
  const authorLinkForMobile: AuthorContactBtnProps[] = [
    { btnType: AUTHOR_CONTACT_TYPE.INSTAGRAM, link: props.authorInsta },
    { btnType: AUTHOR_CONTACT_TYPE.LINKEDIN, link: props.authorLinkedIn },
    { btnType: AUTHOR_CONTACT_TYPE.EMAIL, link: props.authorEmail },
    {
      btnType: AUTHOR_CONTACT_TYPE.ARTICLE_LINK,
      link: props.id,
    },
  ];
  const url = window.location.href + "detail/" + `${props.id}`;

  const onClickDetail = () => {
    props.onClickDetail(props.id);
    props.setIsOpened(true);
    window.history.pushState(null, "", url);
  };

  return (
    <div className="column-component" onClick={onClickDetail}>
      <div className="top-line">
        {/*<Link key={props.id} to={`/detail/${props.id}`}>*/}
        <div className="title">{props.title}</div>
        {/*</Link>*/}
        <div className="title-divide-bar"></div>
        <img
          alt="product-icon"
          className="product-icon"
          src={props.productImage}
        />
        <div className="product-name">{props.product}</div>
        <UxEvaluationTag name={props.uxEvaluation!} />
        {/*<ShareBtn copyUrl={url} />*/}
      </div>
      <div className="content-container">
        {/*<div className="tag-wrapper">*/}
        {/*  {props.categoryTag?.map((el) => {*/}
        {/*    return <CategoryTag name={el} key={el} />;*/}
        {/*  })}*/}

        {/*  {props.patternTag?.map((el) => {*/}
        {/*    return <PatternTag name={el} key={el} />;*/}
        {/*  })}*/}
        {/*  {props.uiComponentTag?.map((el) => {*/}
        {/*    return <UiComponent name={el} key={el} />;*/}
        {/*  })}*/}
        {/*</div>*/}
        <div className="content-wrapper">
          {/*<div className="content">{props.content}</div>*/}
          <UxEvaluationTag name={props.uxEvaluation!} />

          <div className="img-wrapper">
            {props.imageSrc?.map((el, idx) => {
              return (
                <img
                  alt="preview-img"
                  src={el.imageUrl}
                  key={el.imageUrl! + idx}
                />
              );
            })}
          </div>
        </div>
      </div>
      <div className="author-wrapper">
        <div className="author-title">
          <div className="author-text">작성자</div>
          <div className="author-name">{props.author}</div>
        </div>
        <div className="author-contact-box">
          {props.authorEmail !== "" ? (
            <AuthorContactBtn
              btnType={AUTHOR_CONTACT_TYPE.EMAIL}
              link={props.authorEmail}
            />
          ) : null}
          {props.authorInsta !== "" ? (
            <AuthorContactBtn
              btnType={AUTHOR_CONTACT_TYPE.INSTAGRAM}
              link={props.authorInsta}
            />
          ) : null}
          {props.authorLinkedIn !== "" ? (
            <AuthorContactBtn
              btnType={AUTHOR_CONTACT_TYPE.LINKEDIN}
              link={props.authorLinkedIn}
            />
          ) : null}
        </div>
        <div className="author-contact-mobile-box">
          <ShowAuthorContactBtn
            key={props.id}
            keyId={props.id}
            btnType={authorLinkForMobile}
          />
        </div>
      </div>
    </div>
  );
}
